import { forwardRef } from "react";
import Carousel from "react-bootstrap/Carousel";

const Home = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div id="home" ref={ref}>
      <Carousel className="main-carousel">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.unsplash.com/photo-1546483875-ad9014c88eba?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=cathy-pham-3jAN9InapQI-unsplash.jpg&w=1920"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Entrenamiento con pesas</h3>
            <p>Mejora tu fuerza y resistencia.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.unsplash.com/photo-1536922246289-88c42f957773?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=meghan-holmes-wy_L8W0zcpI-unsplash.jpg&w=1920"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Entrenamiento funcional</h3>
            <p>Mejora tu movilidad, equilibrio y habilidades para realizar actividades diarias.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.unsplash.com/photo-1581009137042-c552e485697a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=anastase-maragos-fG0p4Qh_aWI-unsplash.jpg&w=1920"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Desarrolla tu potencial</h3>
            <p>Alcanza tus metas y maximiza tu capacidad física y mental.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
});

Home.displayName = "Home";

export default Home;
