import { useState, useCallback } from "react";
import logo from "src/static/images/logo.png";
import { useWindowDimensions } from "./hooks";
import { FiMenu } from "react-icons/fi";
import { Offcanvas } from "react-bootstrap";
import "src/css/navbar.css";

const MAX_WIDTH = 648;

interface NavBarProps {
  activeSection: string;
}

export default function NavBar({ activeSection }: NavBarProps) {
  const [showPopUp, setShowPopUp] = useState(false);

  const handleShow = useCallback(() => {
    setShowPopUp(true);
  }, [setShowPopUp]);

  const handleHide = useCallback(() => {
    setShowPopUp(false);
  }, [setShowPopUp]);

  const { width } = useWindowDimensions();

  return (
    <div className="navbar">
      <a href="#home" className="logo">
        <img src={logo} alt="" />
      </a>
      {width > MAX_WIDTH ? (
        <NavMenu activeSection={activeSection} />
      ) : (
        <>
          <FiMenu onClick={handleShow} className="popup-button" />

          <Offcanvas show={showPopUp} onHide={handleHide}>
            <Offcanvas.Body>
              <NavMenu isPopUp handleHide={handleHide} activeSection={activeSection} />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
}

interface NavMenuProps {
  isPopUp?: boolean;
  handleHide?: () => void;
  activeSection: string;
}

function NavMenu({ isPopUp, handleHide, activeSection }: NavMenuProps) {
  return (
    <ul className={`navbar-menu ${isPopUp && "popup-menu"}`} onClick={handleHide}>
      <li>
        {/* <NavLink to='/' exact activeClassName='selected'>INICIO</NavLink> */}
        <a href="#home" className={activeSection === "home" ? "selected" : ""}>
          INICIO
        </a>
      </li>
      <li>
        {/* <NavLink to='/plans' activeClassName='selected'>PLANES</NavLink> */}
        <a href="#plans" className={activeSection === "plans" ? "selected" : ""}>
          PLANES
        </a>
      </li>
      <li>
        {/* <NavLink to='/book' activeClassName='selected'>AGENDA TU CLASE</NavLink> */}
        <a href="#book" className={activeSection === "book" ? "selected" : ""}>
          AGENDA TU CLASE
        </a>
      </li>
      {/* <li>
        <NavLink to='/map' activeClassName='selected'>ENCUENTRANOS</NavLink>
        <a href="#map" className={activeSection === "map" ? "selected" : ""}>
          ENCUENTRANOS
        </a>
      </li> */}
    </ul>
  );
}
