import { Css, Palette } from "@homebound/beam";
import { forwardRef } from "react";

const plans = [
  {
    title: "Clase",
    price: "$ 6,000",
    description: "Mantente en forma y saludable",
    bgColor: "linear-gradient(160deg, #00d2ff 0%, #3a47d5 100%)",
  },
  {
    title: "Mensual",
    price: "$ 70,000",
    description: "Mantente en forma y saludable",
    bgColor: "linear-gradient(133deg, #fcff9e 0%, #c67700 100%)",
  },
  {
    title: "3 Veces a la semana",
    price: "$ 55,000",
    description: "Ven y entrena con nosotros 3 veces a la semana.",
    bgColor: "linear-gradient(133deg, #9ebd13 0%, #008552 100%)",
  },
  {
    title: "Semana",
    price: "$ 25,000",
    description: "Mantente en forma y saludable",
    bgColor: "linear-gradient(133deg, #efd5ff 0%, #515ada 100%)",
  },
  {
    title: "Trimestre",
    price: "$ 190,000",
    description: "Mantente en forma y saludable",
    bgColor: "linear-gradient(150deg, #00C9FF 0%, #92FE9D 100%)",
  },
  {
    title: "Semestre",
    price: "$ 350,000",
    description: "Mantente en forma y saludable",
    bgColor: "linear-gradient(45deg, #d53369 0%, #daae51 100%)",
  },
];

const Plans = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div id="plans" ref={ref} css={Css.gray100.$}>
      <div css={Css.df.jcc.aic.pt4.$}>
        <h1>PLANES</h1>
      </div>
      <div css={Css.df.jcc.$}>
        <div css={Css.df.aic.jcc.p4.pt2.add({ flexWrap: "wrap" }).maxwPx(1200).$}>
          {plans.map((plan, i) => (
            <Card key={i} {...plan} />
          ))}
        </div>
      </div>
    </div>
  );
});

interface CardProps {
  title: string;
  price: string;
  description: string;
  bgColor: string;
}

function Card({ title, price, description, bgColor }: CardProps) {
  return (
    <div css={Css.df.fdc.aic.jcc.m2.br24.bshBasic.bgGray100.wPx(280).$}>
      <a
        href="https://api.whatsapp.com/send?phone=573176002363&text=¡Hola!, me gustaría agendar una clase."
        target="_blank"
        rel="noreferrer noopener"
        css={Css.textDecoration("none").w100.$}
      >
        <div css={Css.df.fdc.w100.br12.add({ background: bgColor }).$}>
          <div css={Css.df.fdc.aic.jcc.p2.gray900.bgColor(bgColor).w100.$}>
            <h3 css={Css.xl.m0.$}>{title.toUpperCase()}</h3>
          </div>
          <div css={Css.df.fdc.aic.w100.px2.pb4.$}>
            <h2 css={Css.xl3Bd.gray50.add({ fontWeight: 900 }).m0.$}>
              {price}
              <small css={Css.xs.$}>COP</small>
            </h2>
          </div>
        </div>
        {/* <div css={Css.df.fdc.aic.gray900.w100.p2.borderRadius("0 0 12px 12px").$}>
        <p css={Css.tc.$}>{description}</p>
        <ul>
          {features?.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
      </div> */}
      </a>
    </div>
  );
}

Plans.displayName = "Plans";

export default Plans;
