import { Plans, Home, Book } from "./routes";
import NarBar from "./custom/NavBar";
import Footbar from "./custom/Footbar";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";

function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const NAVBAR_HEIGHT = convertRemToPixels(4);

export default function App() {
  const [activeSection, setActiveSection] = useState(window.location.hash.replace("#", "") || "home");

  const contentRef = useRef<HTMLDivElement>(null);

  //SECTIONS
  const homeRef = useRef<HTMLDivElement>(null);
  const plansRef = useRef<HTMLDivElement>(null);
  const bookRef = useRef<HTMLDivElement>(null);

  const sections = useMemo(() => [homeRef, plansRef, bookRef], [homeRef, plansRef, bookRef]);

  const handleContentScroll = useCallback(() => {
    const currentSec = sections.find((ref) => {
      if (!ref.current) return null;
      const bounding = ref.current.getBoundingClientRect();
      if (-bounding.y + NAVBAR_HEIGHT < bounding.height) {
        return ref;
      }
      return null;
    });
    if (currentSec?.current) {
      setActiveSection(currentSec.current.id);
      if (window.history.pushState) {
        window.history.pushState(null, "", `#${currentSec.current.id}`);
      } else {
        window.location.hash = `#${currentSec.current.id}`;
      }
    }
  }, [sections]);

  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;
      content.addEventListener("scroll", handleContentScroll);
      return () => {
        content.removeEventListener("scroll", handleContentScroll);
      };
    }
  }, [contentRef, handleContentScroll]);

  return (
    <>
      <NarBar activeSection={activeSection} />
      <div className="content" ref={contentRef}>
        {/* <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/plans' component={Plans} />
              </Switch> */}
        <Home ref={homeRef} />
        <Plans ref={plansRef} />
        <Book ref={bookRef} />
      </div>
      {/* <Footbar /> */}
    </>
  );
}
