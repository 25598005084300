import { forwardRef } from "react";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";

const Book = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div id="book" ref={ref}>
      <div className="book">
        <h3>Envíanos un DM</h3>
        <div className="socials">
          <a href="https://www.instagram.com/fit.housee/" target="_blank" rel="noreferrer noopener">
            <AiOutlineInstagram />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=573176002363&text=¡Hola!, me gustaría agendar una clase."
            target="_blank"
            rel="noreferrer noopener"
          >
            <AiOutlineWhatsApp />
          </a>
        </div>
      </div>
    </div>
  );
});

Book.displayName = "Book";

export default Book;
